<template>
    <v-row no-gutters>
        <v-col cols="12">
            <DomainBar :accountId="$route.params.accountId" :domain="$route.params.domain" class="mb-6"></DomainBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-domain', params: { accountId: this.$route.params.accountId } }">Domains</router-link>
                </p>
                </v-col>
            </v-row> -->
        <!-- <v-row justify="center" class="py-5 mt-2">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <h1 class="text-h6 font-weight-light text-center">{{ this.$route.params.domain }}</h1>
            </v-col>
        </v-row> -->
        <v-row justify="center" class="py-5 px-10 mt-8">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                <v-card class="mt-8">
                    <v-toolbar :color="primaryColor" dark flat dense>
                        <v-toolbar-title>Verify domain</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>

                        <!-- <p class="text-overline mb-0">Verification code</p>
                        <template v-if="!verificationCode">
                        <p class="mb-0 pb-0">
                            Getting code...
                        </p>
                        </template>
                        <template v-if="verificationCode">
                        <p class="mb-0 pb-0">
                            {{ verificationCode }}
                        </p>
                        </template> -->
                        <!-- <p class="text-overline mb-0">Domain verification status</p> -->
                        <!-- <template v-if="domain && domain.status === 'verified'">
                        <p class="mb-0 pb-0">
                            <font-awesome-icon :icon="['fas', 'check']" class="green--text"/>
                            Verified
                        </p> -->
                        <!-- <p class="mb-0 pb-0">
                            You can remove the DNS record.
                        </p> -->
                        <!-- <p class="mb-0 pb-0 mt-8">
                            <router-link :to="{ name: 'account-view-domain', params: { accountId: this.$route.params.accountId, domain: this.domain.name }}">Return to {{ domain.name }}</router-link>
                        </p> -->
                        <!-- </template> -->
                        <!-- <template v-if="!domain || domain.status !== 'verified'">
                        <p class="mb-0 pb-0">
                            <font-awesome-icon :icon="['fas', 'warning-triangle']" class="amber--text"/>
                            Not verified
                        </p>
                        </template> -->

                        <template v-if="!verification">
                        <!-- <p class="text-overline mb-0">Domain status</p> -->
                        <p class="mb-0 pb-0">
                            No pending verification requests.
                        </p>
                        <p class="mb-0 pb-0">
                            <v-btn :style="primaryButtonStyle" class="white--text" @click="createVerificationCode">Start</v-btn>
                        </p>
                        </template>

                        <template v-if="verification && verification.status === 'verified'">
                        <!-- <p class="text-overline mb-0">Domain status</p> -->
                        <!-- <p class="mb-0 pb-0">
                            There is a pending verification request.
                        </p> -->
                        <v-alert dense type="success" border="left" elevation="2" class="mt-8">
                            Verified
                        </v-alert>

                            <template v-if="domain">
                                <router-link :to="{ name: 'account-domain-view', params: { accountId: this.$route.params.accountId, domain: this.$route.params.domain } }">Manage domain</router-link>
                            </template>

                            <template v-if="errorDomainNotFound">
                                <v-alert dense type="warning" border="left" elevation="2" class="mt-8">
                                    The domain is not currently assigned to you. Contact customer support.
                                </v-alert>
                                <!-- <router-link :to="{ name: 'account-domain-view', params: { accountId: this.$route.params.accountId, domain: this.$route.params.domain } }">Manage domain</router-link> -->
                            </template>

                        </template>

                        <template v-if="verification && (verification.status === 'new' || verification.status === 'pending')">
                        <!-- <p class="text-overline mb-0">Domain status</p> -->
                        <!-- <p class="mb-0 pb-0">
                            There is a pending verification request.
                        </p> -->
                        <!-- <v-alert dense type="warning" border="left" elevation="2" class="mt-8">
                            There is a pending verification request.
                        </v-alert> -->

                            <p class="text-overline mb-0">Directions</p>
                            <p class="mb-0 pb-0">
                                To prove ownership of the domain, you need to create the following DNS record:
                            </p>
                            <v-simple-table class="mt-8">
                                <template #default>
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Hostname</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <!-- TODO: get the verification prefix from server API, it could be customized for self-hosted libertydns, e.g. "_libertycloud" for LibertyCloud's instance -->
                                            <td><span class="font-weight-bold">TXT</span></td>
                                            <td><span class="font-weight-bold">_libertydns</span><span class="font-weight-light">.{{ verification.domain }}</span></td>
                                            <td><span class="font-weight-bold">{{ verification.code }}</span></td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>

                            <p class="mb-0 pb-0 mt-8">
                                GoDaddy: My Domains -&gt; DNS Management (<TextLink :href="godaddyEditDnsLink" target="_blank">direct link</TextLink>)
                            </p>

                            <template v-if="verification.not_after">
                                <p class="text-overline mb-0 mt-8">Verification code expires</p>
                                <p class="mb-0 pb-0">
                                    {{ verificationCodeNotAfterDisplay }}
                                    <v-tooltip top v-model="durationTooltip">
                                        <template #activator="{ on, attrs }">
                                            <font-awesome-icon :icon="['fas', 'clock']" :color="primaryColor" v-bind="attrs" v-on="on"/>
                                        </template>
                                        <span>{{ verificationCodeDurationDisplay }}</span>
                                    </v-tooltip>
                                </p>
                                <p class="mb-0 pb-0">
                                    <!-- TODO: provide a "start over now" button; using the button should delete/cancel any pending requests for same domain and start a new one -->
                                    If you don't successfully verify the domain by this time, reload this page to try again.
                                </p>
                            </template>
                        </template>

                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState, mapGetters } from 'vuex';
import { toMillis, toText } from '@libertyio/time-util-js';
import DomainBar from '@/components/DomainBar.vue';
import TextLink from '@/components/TextLink.vue';

export default {
    components: {
        DomainBar,
        TextLink,
    },
    data: () => ({
        account: null,
        domain: null,
        verification: null,
        error: null,
        forbiddenError: null,
        // verificationCode: null,
        // verificationCodeExpires: null,
        // verificationCodeNotAfter: null,
        timer: null,
        errorDomainNotFound: false,
        durationTooltip: false,
        computeDurationFrom: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        accountName() {
            return this.isViewReady ? this.account?.name ?? 'Unknown' : '';
        },
        isViewReady() {
            return this.account !== null;
        },
        verificationCodeNotAfterDisplay() {
            try {
                if (typeof this.verification?.not_after === 'number' && this.verification.not_after > 0) {
                    const date = new Date(this.verification.not_after);
                    return date.toString();
                }
                return 'Unknown';
            } catch (err) {
                console.log(`failed to parse verification code not-after date: ${this.verification.not_after}`);
                return 'Unknown';
            }
        },
        verificationCodeDuration() {
            if (typeof this.verification?.not_after === 'number' && this.verification.not_after > 0) {
                const now = this.computeDurationFrom; // Date.now(); // NOTE: using a data property so it gets updated every time user hovers over the clock
                const duration = this.verification.not_after - now;
                if (duration > 0) {
                    return duration;
                }
            }
            return null;
        },
        verificationCodeDurationDisplay() {
            const duration = this.verificationCodeDuration;
            if (duration) {
                return toText(duration);
            }
            return null;
        },
        godaddyEditDnsLink() {
            // TODO: this is brittle, we need to monitor godaddy for changes if we're going to show this, or have a way for users to let us know it is broken
            return this.verification ? `https://dcc.godaddy.com/manage/${this.verification.domain}/dns/` : '';
        },
    },
    watch: {
        focus() {
            // when user switches back to this window (for example after creating the record at their registrar) immediately check again
            if (this.verification && this.timer) {
                clearTimeout(this.timer);
            }
            this.checkVerification();
        },
        durationTooltip(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.computeDurationFrom = Date.now();
            }
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).self.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                if (err.response?.status === 403) {
                    this.forbiddenError = true;
                }
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        // NOTE: see also similar functions in CreateDomainVerify, CreateDomainRegister, PendingDomainList, DomainList, and CreateDomainDialog
        async createDomain() {
            try {
                this.error = false;
                this.$store.commit('loading', { createDomain: true });
                console.log('createDomain');
                const request = {
                    // name: this.name,
                    name: this.$route.params.domain,
                    // agreeToTerms: this.isAgreeToTermsChecked,
                    // interactionId: this.interactionId, // will be present if domain arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the domain to an appropriate location after domain is created
                };
                const response = await this.$client.account(this.$route.params.accountId).domain.create(request);
                console.log('createDomain response: %o', response);
                const { isCreated, error } = response;
                if (!isCreated && error) {
                    switch (error) {
                    case 'DOMAIN_VERIFICATION_REQUIRED':
                        // this.$router.push({ name: 'account-verify-domain', params: { accountId: this.$route.params.accountId, domain: this.$route.params.domain } });
                        this.error = true; // we only attempt to create the domain if the verification was successful, so we should NOT get this error code from the server
                        break;
                    case 'DOMAIN_ALREADY_CLAIMED':
                        this.$router.push({ name: 'account-dispute-domain', params: { accountId: this.$route.params.accountId, domain: this.$route.params.domain } });
                        break;
                    case 'UNKNOWN':
                    default:
                        this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create domain', message: 'Unknown error' });
                        this.error = true;
                        break;
                    }
                }
                if (isCreated) {
                    this.redirectToDomain();
                }
            } catch (err) {
                console.error('failed to create domain', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { createDomain: false });
            }
        },
        async finishVerification() {
            this.createDomain();
        },
        redirectToDomain() {
            this.$router.push({ name: 'account-view-domain', params: { accountId: this.$route.params.accountId, domain: this.$route.params.domain } });
        },
        async loadDomain() {
            try {
                this.error = false;
                this.errorDomainNotFound = false;
                this.$store.commit('loading', { loadDomain: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.get({ name: this.$route.params.domain });
                console.log(`domain/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.domain = response;
                }
            } catch (err) {
                if (err.response?.status === 404) {
                    console.log('domain not found');
                } else {
                    console.error('failed to load domain request', err);
                }
                this.domain = null;
                this.errorDomainNotFound = true;
            } finally {
                this.$store.commit('loading', { loadDomain: false });
            }
        },
        async loadDomainVerificationRequest() {
            try {
                if (this.timer) {
                    clearTimeout(this.timer);
                }

                this.$store.commit('loading', { loadDomainVerificationRequest: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.getVerificationRequest({ domain: this.$route.params.domain });
                console.log(`loadDomainVerificationRequest: response ${JSON.stringify(response)}`);
                if (response?.item) {
                    console.log(`loadDomainVerificationRequest: ${JSON.stringify(response)}`);
                    this.verification = response.item;
                    // this.status = response.status;
                    // this.verificationCode = response.code;
                    // this.verificationCodeExpires = response.expires;
                    // this.verificationCodeNotAfter = response.not_after;
                } else {
                    this.verification = null;
                }
                if (this.verification?.status === 'verified') {
                    this.finishVerification();
                } else {
                    console.log('loadDomainVerificationRequest: start auto-check timer');
                    this.timer = setTimeout(() => { this.checkVerification(); }, toMillis({ seconds: 15 }));
                }
            } catch (err) {
                if (err.response?.status === 404) {
                    console.log('no active domain verification request');
                    // TODO: if no active request, AND if domain is not already verified (we need to call loadDomain) then automatically start the verification; no need to wait for user to press the "start" button
                } else {
                    console.error('failed to load domain verification request', err);
                }
                // this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to get verification code' });
                // this.createVerificationCode();
                this.verification = null;
            } finally {
                this.$store.commit('loading', { loadDomainVerificationRequest: false });
            }
        },
        async createVerificationCode() {
            try {
                this.$store.commit('loading', { createVerificationCode: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.createVerificationRequest({ domain: this.$route.params.domain });
                console.log(`createVerificationCode: response ${JSON.stringify(response)}`);
                if (response?.item) {
                    this.verification = response.item;
                    // this.status = response.status;
                    // this.verificationCode = response.code;
                    // this.verificationCodeExpires = response.expires;
                    // this.verificationCodeNotAfter = response.not_after;
                }
                /*
                if (response?.status === 'verified') {
                    this.loadDomain();
                }
                */
            } catch (err) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create domain verification request' });
            } finally {
                this.$store.commit('loading', { createVerificationCode: false });
            }
        },
        async checkVerification() {
            // NOTE: server does access control check for this, only service admin may use the function
            try {
                if (this.timer) {
                    clearTimeout(this.timer);
                }

                this.$store.commit('loading', { checkVerification: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.checkVerification({ domain: this.$route.params.domain });
                console.log(`domain/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response?.status) {
                    this.verification ??= {};
                    this.$set(this.verification, 'status', response.status.status);
                    // this.verificationCode = response.code;
                    // this.verificationCodeExpires = response.expires;
                    // this.verificationCodeNotAfter = response.not_after;
                }
                if (this.verification?.status === 'verified') {
                    this.finishVerification();
                } else {
                    console.log('checkVerification: continue auto-check timer');
                    this.timer = setTimeout(() => { this.checkVerification(); }, toMillis({ seconds: 15 }));
                }
            } catch (err) {
                if (err.response?.status === 400 && !this.$route.params.domain) {
                    console.error('checkVerification: invalid request');
                    this.$router.replace({ name: 'user-dashboard' });
                } else if (err.response?.status === 400) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to check domain verification status' });
                } else if (err.response?.status === 404) {
                    this.verification = null;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to check domain verification status' });
                }
            } finally {
                this.$store.commit('loading', { checkVerification: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        // this.loadDomain();
        this.loadDomainVerificationRequest();
    },
    beforeRouteLeave(to, from, next) {
        if (this.timer) {
            console.log('beforeRouteLeave: clearing timer');
            clearTimeout(this.timer);
        }
        next();
    },
};
</script>
